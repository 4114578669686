import { post } from '@/utils/request'

export function PostfirmList(data) {
    return post('admin/company/list', data)
}
export function PostfirmMemberList(data) {
    return post('admin/company/member/list', data)
}
export function PostfirmDetail(data) {
    return post('admin/company/detail', data)
}
export function PostfirmHistory(data) {
    return post('admin/company/purchase/history', data)
}
export function postCompanyAssessor(data) {
    return post('admin/company/assessor', data)
}